.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.zomg {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 60px;
  bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.nav,
.detail {
  border: 1px solid black;
  padding: 10px;
}

.mapboxgl-map {
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  bottom: 0;
}

.MuiFab-primary {
  background-color: #fe3b3f;
}
